<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    class="listing--form-modal action--modal"
    @opened="opened"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <section-loading :show="loading" />
      <button type="button" class="close" @click="close">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
      <section class="modal--container">
        <div class="subtitle--text">
          {{ $t('featuredAgent.modal.title') }}
        </div>
        <div class="action--info">
          <div class="info--title">{{ $t('featuredAgent.modal.info') }}</div>
          <div class="row">
            <div class="col-6 info--coin pb-0">
              {{ $t('featuredAgent.modal.slotAmount') }}
            </div>
            <div class="col-6 info--my-coin pb-0">
              <div class="coin--use">
                {{ `${amount} ${$t('featuredAgent.modal.slot')}` }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 info--coin">{{ $t('featuredAgent.modal.usedCoin') }}</div>
            <div class="col-6 info--my-coin">
              <div class="coin--use">
                {{ `${amount * cost} ${$t('general.coin')}` }}
              </div>
              <div class="coin--remaining">
                {{ `${$t('featuredAgent.modal.yourCoin')} ${totalCoins}` }}
              </div>
            </div>
          </div>
        </div>
        <div class="bottom--part" style="margin-bottom: 12px">
          <a class="btn btn-primary" @click="tryBuyFeaturedAgent">
            {{ $t('featuredAgent.modal.btn') }}
          </a>
        </div>
      </section>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';

const SectionLoading = () => import('@/components/content-loading/section-loading');

export default {
  name: 'buy-featured-agent-modal',
  components: {
    SectionLoading,
  },
  computed: {
    ...mapState({
      loading: (state) => state.coins.loading,
      totalCoins: (state) => state.coins.totalCoins,
      selectedAvailabilities: (state) => state.featuredAgent.selectedAvailabilities,
      cost: (state) => state.featuredAgent.cost,
    }),
    amount() {
      return this.selectedAvailabilities.length;
    },
  },
  data() {
    return {
      modalName: 'buy-featured-agent-modal',
      submitAction: null,
    };
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    async beforeOpen(event) {
      if (event.params && event.params.submitAction !== null) {
        this.submitAction = event.params.submitAction;
      }
    },
    async opened() {
      await Promise.all([
        this.$store.dispatch('coins/getTotalCoins'),
        this.$store.dispatch('featuredAgent/getCost'),
      ]);
    },
    async tryBuyFeaturedAgent() {
      const now = new Date();
      const month = now.getMonth() + 1; // Adjust to match one-based month
      const year = now.getFullYear();

      const currentMonthSelection = this.selectedAvailabilities.find(function (item) {
        return item.month === month && item.year === year;
      });
      if (currentMonthSelection) {
        const result = await this.$swal({
          title: 'Perhatian!',
          html: `Untuk pembelian Anda di <b>bulan ${currentMonthSelection.month_name} ${currentMonthSelection.year}</b> berlaku di bulan berjalan, namun akan tetap <b>mengkonsumsi sebanyak ${this.cost} koin</b>.
Jika anda sudah memahami ini, silahkan tekan tombol setuju di bawah ini.`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Setuju',
          cancelButtonText: 'Tidak Setuju',
        });

        if (result.value) {
          await this.buyFeaturedAgent();
        }
      } else {
        await this.buyFeaturedAgent();
      }
    },
    async buyFeaturedAgent() {
      const result = await this.$swal({
        title: 'Beli slot agen pilihan?',
        text: `Apakah Anda yakin untuk beli ${this.selectedAvailabilities.length} slot agen pilihan?`,
        type: 'question',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('general.yes'),
        cancelButtonText: this.$i18n.t('general.cancel'),
      });

      if (result.value) {
        this.$emit('submit');
        if (this.submitAction !== null) {
          this.submitAction();
        }
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/utils/variables';

.subtitle--text {
  margin-top: 20px;
  @media #{$phones} {
    margin-top: 60px;
    text-align: center;
  }
}
</style>
